import axios from 'axios'
import router from '@/router';

export const reportEditPlanModule = {
    namespaced: true,

    state:() => ({
        reportData: {},
        reportLoading: true,
        excelData: {},
    }),

    mutations: {
        setReportData(state, data) {
            state.reportData = data
        },

        setExcelData(state, data) {
            state.excelData = data
        }
    },

    actions: {
        async getReportData({ commit, state }) {
            state.reportLoading = true
            try {
                const response = await axios.get("https://admin.tcode.online/api/sale-plans/" + router.currentRoute.value.query['shop'] + '?month=' + router.currentRoute.value.query['month'], {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    }
                })
                console.log(response.data);
                commit('setReportData', response.data)
                commit('setExcelData', response.data)
            } catch(err) { // Если есть ошибка
                alert(err)
            }

            state.reportLoading = false
        },

        async changeData({}, data) {
            let summary = null
            console.log(data);

            switch(data.type) {
                case 'revenue':
                    summary = {
                        revenue: data.value 
                    }
                    break;
                case 'orders':
                    summary = {
                        orders: data.value 
                    }
                    break;    
                case 'orders':
                    summary = {
                        orders: data.value 
                    }
                    break;
                case 'efficient':
                    summary = {
                        efficient: data.value 
                    }
                    break;
                case 'order_time':
                    summary = {
                        order_time: data.value 
                    }
                break;
                case 'nps':
                    summary = {
                        nps: data.value 
                    }
                    break;
                case 'percentage_kiosks':
                    summary = {
                        percentage_kiosks: data.value 
                    }
                break;
                case 'smartmetrics':
                    summary = {
                        smartmetrics: data.value 
                    }
                    break;
            }

            try {
                const response = await axios.patch("https://admin.tcode.online/api/sale-plans/" + router.currentRoute.value.query['shop'] + '/' + data.id + '/',
                summary,
                {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    }
                })
            } catch(err) { // Если есть ошибка
                alert(err)
            }
        },
    }
}