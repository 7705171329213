<template>
<div class="window window-goals window_full">
    <div class="window-header">
        <img src="@/assets/img/icons/icon-goals.svg" alt="Иконка" class="window-header__icon" />
        <div class="window-header__title">
            Главные цели на месяц <span>( {{ goalsCount.completed }} / {{ goalsCount.total }} )</span>
        </div>
    </div>
    <div v-if="goalsList.length === 0" class="window-item window-item_empty">
        <p>Нет целей</p>
    </div>
    <div class="window-item" v-for="goal in goalsList" :key="goal.id">
        <p :class="goal.is_completed ? 'complete' : ''">{{ goal.name }}</p>
        <div class="window-item-right">
            <span v-if="goal.is_completed" class="window-item__status window-item__status_complete">
                <div class="circle">
                    <i></i>
                </div>
                сделано
            </span>
            <span> {{ goal.current_value }} / {{ goal.planned_value }} </span>
        </div>
    </div>
</div>
</template>
<script>
export default {
    props: {
        goalsCount: {
            type: Object,
            default: null
        },

        goalsList: {
            type: Object,
            default: null
        },
    }
}
</script>