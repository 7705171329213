import axios from "axios";
import api from "@/api";

export const notificationsModule = {
    namespaced: true,

    state:() => ({
        notifications: null,
        not_readed_count: 0,
        new_notification: null,
    }),

    mutations: {
        setNotifications(state, data) {
            const count = data.filter(x => x.readed_at == null).length
            data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))

            state.notifications = data
            state.not_readed_count = count
        },

        setNewNotifications(state, data) {
            data.message.time = `${new Date().getHours()}:${new Date().getMinutes()}`
            state.new_notification = data.message
        },

        clearNewNotifications(state) {
            state.new_notification = null
        }
    },

    actions: {
        async fetchNotifications({ commit }) {
            try {
                const response = await axios.get(api + "/notifications?page_size=5", {
                    headers: {
                        Authorization: "Token " + localStorage.getItem("token"),
                    },
                });
                commit("setNotifications", response.data.results);
            } catch (err) {
                alert(err);
            }
        },

        notificationConnect({ commit, dispatch }, user) {
            console.log("Соединение с WebSocket...")
            this.connection = new WebSocket(`wss://admin.tcode.online/ws/notifications/user/${user.id}/`)

            this.connection.onmessage = function(event) {
                commit('setNewNotifications', JSON.parse(event.data))
                dispatch('fetchNotifications')
            }

            this.connection.onopen = function(event) {
                console.log(event)
                console.log("Соединение с WebSocket, успешно...")
            }
        },

        async readNotifications({ state, dispatch }) {
            for(let i = 0; i < state.notifications.length; i++) {
                if(!state.notifications[i].readed_at) {
                    try {
                        const response = await axios.patch(api + "/notifications/" + state.notifications[i].id,
                            {
                                readed_at: new Date().toISOString()
                            },
                            {
                            headers: {
                                Authorization: "Token " + localStorage.getItem("token"),
                            },
                        });
                    } catch (err) {
                        alert(err);
                    }
                }
            }

            dispatch('fetchNotifications')
        },
    }
}