import axios from 'axios'
import router from '@/router';
import route from '@/router';

export const сorporateOrdersProductsModule = {
    namespaced: true,

    state:() => ({
        shops: null,
        categories: null,
        products: null,
        cart: {
            items: [],
            shop_code: null,
            productCounts: 0,
            totalPrice: 0,
        },
        orderCreated: false,
        pages: null,
    }),

    mutations: {
        setShops(state, data) {
            for(let i = 0; i < data.length; i++) {
                data[i].label = data[i].name
                data[i].value = data[i].id
            }

            state.shops = data
        },

        setCategories(state, data) {
            for(let i = 0; i < data.length; i++) {
                data[i].label = data[i].real_name
                data[i].value = data[i].id
                data[i].checked = true
            }

            state.categories = data
        },

        setProducts(state, data) {
            for(let i = 0; i < data.length; i++) {
                data[i].quantity = 0
            }
            console.log(data);
            state.products = data
        },

        setPages(state, data) {
            state.pages = data
        },
    },

    actions: {
        getShops({ commit }) {
            axios
            .get("https://admin.tcode.online/api/projects/tcode/shops/", {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                commit('setShops', response.data)
            })
            .catch((error) => {
                console.log(error);
            });
        },

        getCategories({ state, commit }) {
            const shop_code = route.currentRoute.value.query.shop_code
            const project_code = state.shops.find(x => x.code == shop_code).project_code

            axios
            .get(`https://admin.tcode.online/api/projects/${project_code}/shops/${shop_code}/categories/`, {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                commit('setCategories', response.data.results)
            })
            .catch((error) => {
                console.log(error.response);
            });
        },

        getProducts({ state, rootState, commit, dispatch }) {
            rootState.isLoading = true

            if(router.currentRoute.value.fullPath.split('?')[1]) {
                const shop_code = route.currentRoute.value.query.shop_code
                const project_code = state.shops.find(x => x.code == shop_code).project_code
                if(state.cart.shop_code !== shop_code) {
                    dispatch('orderClear')
                }
                state.cart.shop_code = shop_code

                axios
                .get(`https://admin.tcode.online/api/projects/${project_code}/shops/${shop_code}/products?${router.currentRoute.value.fullPath.split('?')[1]}`, {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    }
                })
                .then((response) => {
                    commit('setProducts', response.data.results)
                    commit('setPages', response.data.num_pages)

                    rootState.isLoading = false
                })
                .catch((error) => {
                    console.log(error.response);
                });
            }
        },

        inputProductCount({ state, dispatch }, data) {
            if(data.type === 'plus') {
                const product = state.products.find(product => product.id === data.id)

                const elem = state.cart.items.find(item => item.id === product.id)
                if(elem) {
                    elem.quantity += product.multiplicity
                    
                    localStorage.setItem('corporate_cart', JSON.stringify(state.cart))
                } else {
                    product.quantity = product.multiplicity
                    state.cart.items.push(product)

                    localStorage.setItem('corporate_cart', JSON.stringify(state.cart))
                }
                
                dispatch('calculatePrice')
            } else if (data.type === 'minus') {
                for(let i = 0; i < state.cart.items.length; i++) {
                    if(state.cart.items[i].id === data.id && state.cart.items[i].quantity > 0) {
                        state.cart.items[i].quantity -= state.cart.items[i].multiplicity
                        if(state.cart.items[i].quantity < 1) {
                            state.cart.items.splice(i, 1)
                        }

                        localStorage.setItem('corporate_cart', JSON.stringify(state.cart))
                    }
                }

                dispatch('calculatePrice')
            } else {
                for(let i = 0; i < state.cart.items.length; i++) {
                    if(state.cart.items[i].id === data.id) {    
                        state.cart.items.splice(i, 1)
                    }

                    localStorage.setItem('corporate_cart', JSON.stringify(state.cart))
                }
                dispatch('calculatePrice')
            }
        },

        calculatePrice({ state }) {
            state.cart.totalPrice = 0
            state.cart.productCounts = 0

            for(let i = 0; i < state.cart.items.length; i++) {
                if(state.cart.items[i].quantity > 0) {
                    state.cart.totalPrice += state.cart.items[i].quantity * state.cart.items[i].price_float.toFixed(2)
                    state.cart.productCounts ++
                }
            }
        },

        sendOrder({ state, dispatch }, data) {
            const shop_code = route.currentRoute.value.query.shop_code
            const project_code = state.shops.find(x => x.code == shop_code).project_code

            if(typeof data.date !== 'string') {
                data.date = data.date.toLocaleDateString().split('.').reverse().join('-')
            }

            let order = {
                items: state.cart.items,
                delivery_date: data.date,
                editable_order: data.editable,
                comment: data.comment
            }

            axios
            .post(`https://admin.tcode.online/api/projects/${project_code}/shops/${shop_code}/personal/orders/`, 
                order,
                {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    },
                })
            .then((response) => {
                if(response.data.created) {
                    dispatch('orderClear')
                    dispatch('changeOrderStatus', true)
                } else {
                    dispatch('orderClear')
                    alert(response.data)
                }
            })
            .catch((error) => {
                console.log(error.response);
            });
        },

        fetchCart({ state, dispatch }) {
            const cart = JSON.parse(localStorage.getItem('corporate_cart')) ?? null
            
            if(cart) {
                state.cart = cart
            }

            dispatch('calculatePrice')
        },  

        orderClear({ state }) {
            state.cart.productCounts = 0
            state.cart.totalPrice = 0
            state.cart.date = null
            state.cart.items = []

            localStorage.removeItem('corporate_cart')
        },

        changeOrderStatus({ state }, status) {
            state.orderCreated = status
        },

        async productEdit({ state, dispatch }, data) {
            const shop_code = route.currentRoute.value.query.shop_code
            const project_code = state.shops.find(x => x.code == shop_code).project_code

            try {
                const response = await axios.patch(`https://admin.tcode.online/api/projects/${project_code}/shops/${shop_code}/products/${data.id}`,
                data.data,
                {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    }
                })
                dispatch('getProducts')
            } catch(err) { // Если есть ошибка
                alert(err)
            }
        },

        // async addNewProduct({ dispatch }, data) {
        //     try {
        //         const response = await axios.post("https://admin.tcode.online/api/projects/tubatay-production/shops/production/products/",
        //         data,
        //         {
        //             headers: {
        //                 Authorization: 'Token ' + localStorage.getItem('token')
        //             }
        //         })
        //         dispatch('getProducts')
        //     } catch(err) { // Если есть ошибка
        //         console.log(err)
        //     }
        // },

        // async productDelete({ dispatch }, id) {
        //     try {
        //         const response = await axios.delete(`https://admin.tcode.online/api/projects/tubatay-production/shops/production/products/${id}/`,
        //         {
        //             headers: {
        //                 Authorization: 'Token ' + localStorage.getItem('token')
        //             }
        //         })

        //         console.log(response)
        //     } catch(err) { // Если есть ошибка
        //         console.log(err)
        //     }
        // }
    }
}