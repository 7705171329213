<template>
    <div class="window window-polls window_full">
        <div class="window-header">
            <img src="@/assets/img/icons/icon-tasks.svg" alt="Иконка" class="window-header__icon" />
            <div class="window-header__title">Результаты обучения</div>
        </div>

        <div class="window-item window-item_polls" v-for="poll in polls" :key="poll.id"
            :class="polls.length === 1 ? 'only_one' : ''">
            <div class="window-item-header">
                <span>
                    {{ poll.name }}
                </span>
                <span class="window-item-header__status">
                    {{ poll.comment }}
                </span>
            </div>
            <div class="window-item-body" v-if="poll.comment">
                <span v-for="(result, index) in poll.results" :key="index">
                    {{ result }}
                    <i v-if="poll.results.length != 1">, </i>
                </span>
            </div>
        </div>
        <div v-if="polls.length === 0" class="window-item window-item_empty">
            <p>У вас пока нет задач</p>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        polls: {
            type: Object,
            default: null
        }
    },
}
</script>