import axios from 'axios'
import router from '@/router';
import route from '@/router';

export const сorporateOrdersListModule = {
    namespaced: true,

    state:() => ({
        shops: null,
        orders: null,
        suppliers: null,
        currentOrder: null,
        stateSearchQuery: '',
        products: null,
        pages: null,
        edited_succes: null,
    }),

    mutations: {
        setShops(state, data) {
            const shop_code = router.currentRoute.value.query.shop_code

            for(let i = 0; i < data.length; i++) {
                if(Array.isArray(shop_code)) {
                    const elem = shop_code.find(item => item == data[i].id)

                    if(data[i].id == elem) {
                        data[i].checked = true
                    } else {
                        data[i].checked = false
                    }
                } else {
                    if(data[i].id == shop_code) {
                        data[i].checked = true
                    } else {
                        data[i].checked = false
                    }
                }
            }
            state.shops = data
        },

        setOrders(state, data) {
            state.orders = data
        },

        setCurrentOrder(state, data) {
            data.delivery_datetime_to = data.delivery_datetime_to.split('T')[0]
            for(let i = 0; i < data.items.length; i++) {
                data.items[i].edited = false
            }

            state.currentOrder = data
        },

        setProducts(state, data) {
            state.products = data
        },

        setSearchQuery(state, searchQuery) {
            state.stateSearchQuery = searchQuery
        },

        setPages(state, data) {
            state.pages = data
        },

        setEdited(state, bool) {
            state.edited_succes = bool
        }
    },

    getters: {
        getProducts(state) {
            if(state.products) {
                return state.products.filter(product => product.name.toLowerCase().includes(state.stateSearchQuery.toLowerCase()))
            }
        }
    },

    actions: {
        getShops({ commit }) {
            axios
            .get("https://admin.tcode.online/api/projects/tcode/shops/", {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                for(let i = 0; i < response.data.length; i++) {
                    response.data[i].label = response.data[i].name
                    response.data[i].value = response.data[i].id
                }

                commit('setShops', response.data)
            })
            .catch((error) => {
                console.log(error);
            });
        },

        getOrders({ state, rootState, commit }) {
            rootState.isLoading = true

            const shop_code = route.currentRoute.value.query.shop_code
            const project_code = state.shops.find(x => x.code == shop_code).project_code

            if(state.shops) {
                axios
                .get(`https://admin.tcode.online/api/projects/${project_code}/shops/${shop_code}/personal/orders?${router.currentRoute.value.fullPath.split('?')[1]}`, {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    }
                })
                .then((response) => {
                    commit('setOrders', response.data.results)
                    commit('setPages', response.data.num_pages)
                    rootState.isLoading = false
                })
                .catch((error) => {
                    console.log(error);
                });
            }
        },

        getCurrentOrder({ state, commit }, id) {
            const shop_code = route.currentRoute.value.query.shop_code
            const project_code = state.shops.find(x => x.code == shop_code).project_code

            axios
            .get(`https://admin.tcode.online/api/projects/${project_code}/shops/${shop_code}/personal/orders/${id}`, {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                commit('setCurrentOrder', response.data)
            })
            .catch((error) => {
                console.log(error);
            });
        },

        changeOrderStatus({ state, dispatch }, data) {
            const shop_code = route.currentRoute.value.query.shop_code
            const project_code = state.shops.find(x => x.code == shop_code).project_code

            axios
            .get(`https://admin.tcode.online/api/projects/${project_code}/shops/${shop_code}/personal/order-edit/${data.id}/?status=${data.status}`, {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                if(response.data.success) {
                    dispatch('getOrders')
                    state.currentOrder = null
                }
            })
            .catch((error) => {
                console.log(error.response);
            });
        },

        sendInvoice({ state, dispatch }, data) {
            const shop_code = route.currentRoute.value.query.shop_code
            const project_code = state.shops.find(x => x.code == shop_code).project_code

            axios
            .get(`https://admin.tcode.online/api/projects/${project_code}/shops/${shop_code}/personal/order-edit/${data.id}?${data.type}=true`, {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                dispatch('getOrders')
            })
            .catch((error) => {
                console.log(error);
            });
        },

        orderDelete({ state, dispatch }, id) {
            const shop_code = route.currentRoute.value.query.shop_code
            const project_code = state.shops.find(x => x.code == shop_code).project_code

            axios
            .get(`https://admin.tcode.online/api/projects/${project_code}/shops/${shop_code}/personal/order-edit/${id}/?order_del=true`, {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                if(response.data.success) {
                    state.currentOrder = null
                    dispatch('getOrders')
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },

        inputProductCount({ state, dispatch }, data) {
            if(data.type === 'plus') {
                for(let i = 0; i < state.currentOrder.items.length; i++) {
                    if(state.currentOrder.items[i].id === data.id) {
                        state.currentOrder.items[i].quantity = state.currentOrder.items[i].quantity + Number(state.currentOrder.items[i].product.multiplicity)
                        state.currentOrder.items[i].sum_float = state.currentOrder.items[i].price_float * state.currentOrder.items[i].quantity
                        state.currentOrder.items[i].edited = true
                    }
                }
            } else if (data.type === 'minus') {
                for(let i = 0; i < state.currentOrder.items.length; i++) {
                    if(state.currentOrder.items[i].id === data.id && state.currentOrder.items[i].quantity > 1) {
                        state.currentOrder.items[i].quantity = state.currentOrder.items[i].quantity - Number(state.currentOrder.items[i].product.multiplicity)
                        state.currentOrder.items[i].sum_float = state.currentOrder.items[i].price_float * state.currentOrder.items[i].quantity
                        state.currentOrder.items[i].edited = true
                    }
                }
            }
        },

        inputProductPrice({ state }, data) {
            const new_price = data.event.target.value
            if(data.old_price != new_price) {
                const item = state.currentOrder.items.find(x => x.id == data.id)
                item.product.price_float = Number(new_price)
                item.price = Number(new_price)
                item.edited = true
            }
        },

        inputProductRatio({ state }, data) {
            const new_ratio = data.event.target.value

            if(data.old_ratio != new_ratio) {
                const item = state.currentOrder.items.find(x => x.id == data.id)
                item.ratio = Number(new_ratio.replace(/,/g, "."))
                item.edited = true
            }

        },

        // inputProductDelete({ state }, id) {
        //     for(let i = 0; i < state.currentOrder.items.length; i++) {
        //         if(state.currentOrder.items[i].id === id) {
        //             state.currentOrder.items[i].delete_edited = true
        //             if(state.currentOrder.items[i].is_cancelled) {
        //                 state.currentOrder.items[i].is_cancelled = false
        //             } else {
        //                 state.currentOrder.items[i].is_cancelled = true
        //             }
        //         }
        //     }
        // },

        changeOrderProductEdit({ state, commit, dispatch }, data) {
            const shop_code = route.currentRoute.value.query.shop_code
            const project_code = state.shops.find(x => x.code == shop_code).project_code

            axios
            .post(`https://admin.tcode.online/api/projects/${project_code}/shops/${shop_code}/personal/order-edit/${data.order_id}/`,
            {
                items: data.items
            },
            {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                for(let i = 0; i < state.currentOrder.items.length; i++) {
                    if(state.currentOrder.items[i].id === data.item_id) {
                        state.currentOrder.items[i].edited = false
                    }
                }
                if(response.status == 200) {
                    commit('setEdited', true)
                    dispatch('getCurrentOrder', data.order_id)
                    dispatch('getOrders')
                }
            })
            .catch((error) => {
                console.log(error.response);
                commit('setEdited', false)
            });
        },

        orderProductDelete({ state, rootState, dispatch }, data) {
            const shop_code = route.currentRoute.value.query.shop_code
            const project_code = state.shops.find(x => x.code == shop_code).project_code

            if(!data.item.is_cancelled) {
                rootState.isLoading = true

                axios
                .get(`https://admin.tcode.online/api/projects/${project_code}/shops/${shop_code}/personal/order-edit/${data.order_id}/?item_del=${data.item.id}`, {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    }
                })
                .then((response) => {
                    if(response.data.success) {
                        dispatch('getCurrentOrder', data.order_id)
                        dispatch('getOrders')
                        rootState.isLoading = false
                    }
                })
                .catch((error) => {
                    console.log(error.response);
                    rootState.isLoading = false
                });
            } else {
                rootState.isLoading = true

                axios
                .get(`https://admin.tcode.online/api/projects/${project_code}/shops/${shop_code}/personal/order-edit/${data.order_id}?item_return=${data.item.id}`, {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    }
                })
                .then((response) => {
                    if(response.data.success) {
                        dispatch('getCurrentOrder', data.order_id)
                        dispatch('getOrders')
                        rootState.isLoading = false
                    }
                })
                .catch((error) => {
                    console.log(error.response);
                    rootState.isLoading = false
                });
            }   
        },

        changeOrderProductPrice({ state }, data) {
            const shop_code = route.currentRoute.value.query.shop_code
            const project_code = state.shops.find(x => x.code == shop_code).project_code

            axios
            .get(`https://admin.tcode.online/api/projects/${project_code}/shops/${shop_code}/personal/order-edit/${data.order_id}/?item_edit=${data.item_id}&item_price=${data.item_price}`, {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                for(let i = 0; i < state.currentOrder.items.length; i++) {
                    const item = state.currentOrder.items.find(x => x.id == data.item_id)
                    item.price_edited = false
                }
            })
            .catch((error) => {
                console.log(error.response);
            });
        },

        changeOrderProductRatio({ state }, data) {
            const shop_code = route.currentRoute.value.query.shop_code
            const project_code = state.shops.find(x => x.code == shop_code).project_code

            axios
            .get(`https://admin.tcode.online/api/projects/${project_code}/shops/${shop_code}/personal/order-edit/${data.order_id}/?item_edit=${data.item_id}&item_ratio=${data.item_ratio}`, {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                for(let i = 0; i < state.currentOrder.items.length; i++) {
                    const item = state.currentOrder.items.find(x => x.id == data.item_id)
                    item.ratio_edited = false
                }
            })
            .catch((error) => {
                console.log(error.response);
            });
        },

        orderDownload({ state }, id) {
            const shop_code = route.currentRoute.value.query.shop_code
            const project_code = state.shops.find(x => x.code == shop_code).project_code

            axios
                .get(`https://admin.tcode.online/api/projects/${project_code}/shops/${shop_code}/personal/order-edit/${id}/?export=xlsx`, {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    },
                    responseType: 'blob'
                })
                .then((response) => {
                    const url = URL.createObjectURL(response.data)
                    const a = document.createElement('a')
                    a.href = url
                    a.download = `Заказ_${id}.xls`
                    a.style.display = 'none'
                    document.body.appendChild(a)
                    a.click()
                    a.remove()
                    URL.revokeObjectURL(url)
                })
                .catch((error) => {
                    console.log(error.response);
                });
        },

        changeOrderComment({ state }, data) {
            const shop_code = route.currentRoute.value.query.shop_code
            const project_code = state.shops.find(x => x.code == shop_code).project_code

            axios
            .post(`https://admin.tcode.online/api/projects/${project_code}/shops/${shop_code}/personal/order-edit/${data.id}/`, 
            {
                comment: data.comment
            },
            {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                dispatch('getOrders')
            })
            .catch((error) => {
                console.log(error.response);
            });
        },

        changeOrderDate({ state, dispatch }, data) {
            const shop_code = route.currentRoute.value.query.shop_code
            const project_code = state.shops.find(x => x.code == shop_code).project_code

            let date = data.delivery_datetime_to
            if(typeof date !== 'string') {
                date = date.toLocaleDateString().split('.').reverse().join('-')
            }

            axios
            .get(`https://admin.tcode.online/api/projects/${project_code}/shops/${shop_code}/personal/order-edit/${data.id}?datesave=${date}`,
            {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                dispatch('getOrders')
            })
            .catch((error) => {
                console.log(error.response);
            });
        },

        fetchProducts({ state, commit }) {
            const shop_code = route.currentRoute.value.query.shop_code
            const project_code = state.shops.find(x => x.code == shop_code).project_code

            axios
            .get(`https://admin.tcode.online/api/projects/${project_code}/shops/${shop_code}/products?ordering=name`,
            {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                console.log(response.data);
                commit('setProducts', response.data.results)
            })
            .catch((error) => {
                console.log(error.response);
            });
        },

        addNewProduct({ state, dispatch }, data) {
            const shop_code = route.currentRoute.value.query.shop_code
            const project_code = state.shops.find(x => x.code == shop_code).project_code

            axios
            .get(`https://admin.tcode.online/api/projects/${project_code}/shops/${shop_code}/personal/order-edit/${data.order}?product_add=${data.product}`,
            {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                if(response.data.success) {
                    dispatch('getCurrentOrder', response.data.order.id)
                    dispatch('getOrders')
                }
            })
            .catch((error) => {
                console.log(error.response);
            });
        }
    }
}