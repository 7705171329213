import axios from 'axios'
import api from '@/api'
import router from "@/router";

export const learningModule = {
    namespaced: true,

    state:() => ({
        loading: true,
        chapters: null,
        polls: null,
        test: null,
        poll_progress_id: null,
        points: null,
        total_points: null,
        projects: null,
        selected_project: null,
        is_succeded: false,
    }),

    mutations: {
        setChapters(state, data) {
            state.chapters = data
        },

        setPolls(state, data) {
            state.polls = data
        },

        setTest(state, data) {
            state.test = data
        },

        setPollProgressId(state, data) {
            state.poll_progress_id = data
        },

        setProjects(state, data) {
            state.projects = data['project']

            for(let i = 0; i < data['project'].choices.length; i++) {  
                if(data['project'].choices[i].checked) {
                    state.selected_project = data['project'].choices[i].value
                }
            }
        },

        changeProject(state, data) {
            state.selected_project = data
        }

    },

    actions: {
        getChapters({ state, commit }) {
            axios
            .get(`https://admin.tcode.online/api/projects/${state.selected_project}/chapters/`, {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                commit('setChapters', response.data)
            })
            .catch((error) => {
                console.log(error.response);
            });
        },

        getPolls({ state, commit }) {
            axios
            .get(`https://admin.tcode.online/api/projects/${state.selected_project}/polls/`, {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                commit('setPolls', response.data)
            })
            .catch((error) => {
                console.log(error.response);
            });
        },

        getTest({ state, commit }, id) {
            state.points = null

            axios
            .get(`https://admin.tcode.online/api/projects/${state.selected_project}/polls/${id}`, {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                commit('setTest', response.data)
            })
            .catch((error) => {
                console.log(error.response);
            });
        },

        endTest({ state }, data) {
            const answers = {}
            for(let i = 0; i < data.questions.length; i++) {
                const checked = data.questions[i].answer_choices.filter(x => x.checked).map(x => x.id);

                if(checked) {
                    answers[data.questions[i].id] = checked
                }
            }
            console.log(answers);
            axios
            .post(`https://admin.tcode.online/api/projects/${state.selected_project}/poll-progress/`, {
                poll_id: data.id,
                answers: answers

            },
            {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                if(response.data.is_completed) {
                    state.total_points = response.data.questions_count
                    state.points = response.data.correct_answers_count
                    state.is_succeded = response.data.is_succeded
                }
            })
            .catch((error) => {
                console.log(error.response);
            });
        },

        async getProjects({ commit }) {
            try {
                const response = await axios.get("https://admin.tcode.online/api/reports/study/filterset/", {
                    headers: {
                        Authorization: "Token " + localStorage.getItem("token"),
                    },
                });
                commit("setProjects", response.data);
            } catch (err) {
                // Если есть ошибка
                console.log(err);
            }
        },
    }
}