<template>
    <div class="modal-notification-wrapper" @click="$emit('closeModal')">
        <div class="modal-notification">
            <div class="modal-notification-header">
                <p>Уведомления</p>
                <span class="modal-notification-header__btn" @click="readNotifications()">
                    <svg class="opened" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3 9.703V19C3 20.105 3.895 21 5 21H18.999C20.104 21 20.999 20.105 20.999 19V9.703" stroke="#2E343B" stroke-width="1.1" stroke-linecap="round" stroke-linejoin="round"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.88606 8.044L10.8801 3.339C11.5571 2.884 12.4421 2.884 13.1191 3.339L20.1131 8.044C21.2911 8.837 21.2911 10.57 20.1131 11.363L14.2391 15.315C12.8851 16.226 11.1151 16.226 9.76106 15.315L3.88706 11.363C2.70806 10.57 2.70806 8.836 3.88606 8.044Z" stroke="#2E343B" stroke-width="1.1" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M9.11997 14.88L3.58997 20.41" stroke="#2E343B" stroke-width="1.1" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M20.41 20.41L14.88 14.88" stroke="#2E343B" stroke-width="1.1" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <svg class="closed" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.3739 12.718L19.8799 9.663C20.5809 9.189 20.9999 8.398 20.9999 7.552V7.552C20.9999 6.142 19.8579 5 18.4489 5H5.56589C4.15689 5 3.01489 6.142 3.01489 7.551V7.551C3.01489 8.397 3.43389 9.188 4.13489 9.663L8.64089 12.718C10.6739 14.096 13.3409 14.096 15.3739 12.718V12.718Z" stroke="#2E343B" stroke-width="1.1" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M3 7.551V17C3 18.657 4.343 20 6 20H18C19.657 20 21 18.657 21 17V7.552" stroke="#2E343B" stroke-width="1.1" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <span class="tooltip">Отметить прочитанным</span>
                </span>
            </div>
            <div class="modal-notification-body">
                <div
                    class="modal-notification-body-item"
                    v-for="item in notifications"
                    :key="item.id"
                >
                    <div class="modal-notification-body-item__title">
                        <h4>{{ item.title }}</h4>
                        <span
                            class="circle"
                            :class="!item.readed_at ? 'active' : ''"
                        ></span>
                    </div>
                    <p>{{ item.body }}</p>
                    <span>{{ new Date(item.created_at).toLocaleDateString() }}</span>
                </div>
            </div>
            <div class="modal-notification-footer">
                <span class="btn">Смотреть все уведомления</span>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions } from 'vuex';

export default {
    props: {
        notifications: {
            type: Array,
            default: null
        }
    },

    methods: {
        ...mapActions({
            readNotifications: 'notifications/readNotifications'
        })
    }
}
</script>
<style scoped lang="sass">
.modal-notification
    position: absolute
    padding: 0 10px
    width: 330px
    background: var(--color-white)
    border-bottom-left-radius: 6px
    border-bottom-right-radius: 6px
    box-shadow: 0px 10px 30px 0px #00000040
    border-radius: 5px
    &-wrapper
        position: fixed
        top: 0
        right: 0
        padding-top: 74px
        padding-right: 24px
        display: flex
        justify-content: flex-end
        align-items: flex-start
        width: 100dvw
        height: 100dvh
        z-index: 9999
        cursor: default
    &-header
        padding: 0 10px
        display: flex
        align-items: center
        justify-content: space-between
        height: 50px
        font-size: 14px
        font-weight: 700
        &__btn
            position: relative
            display: flex
            align-items: center
            justify-content: center
            width: 24px
            height: 24px
            cursor: pointer
            & .opened, .closed
                position: absolute
                top: 0
                right: 0
                transition: .2s ease
            & .opened
                opacity: 0
            & .tooltip
                position: absolute
                left: -42px
                bottom: 28px
                display: none
                align-items: center
                justify-content: center
                width: 185px
                height: 50px
                font-size: 12px
                color: #fff
                background: #464646
                border-radius: 5px
                transform: translateX(-50%)
            &:hover .tooltip
                display: flex
            &:hover .opened
                opacity: 1
            &:hover .closed
                opacity: 0
    &-body
        color: var(--color-text)
        &-item
            padding: 10px
            display: flex
            flex-direction: column
            align-items: flex-start
            gap: 8px
            &:not(:last-child)
                border-bottom: 1px solid #D8D8D8
            &__title
                display: flex
                align-items: center
                justify-content: space-between
                width: 100%
                gap: 16px
                & .circle
                    width: 10px
                    height: 10px
                    border-radius: 1000px
                    background: #D8D8D8
                    &.active
                        background: #7992F9
            & p
                font-size: 12px
                text-align: start
            & span
                font-size: 10px
                color: #808F9F
    &-footer
        padding: 12px 10px
        border-bottom-left-radius: 6px
        border-bottom-right-radius: 6px
        transition: .2s ease
</style>