<template>
    <div v-if="item" class="new-notifications-modal-wrapper">
        <div class="new-notifications-modal">
            <div class="new-notifications-modal-header">
                <p>У вас новое сообщение</p>
                <span @click="closeModal()">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 1L11 11" stroke="#808F9F" stroke-width="1.1" stroke-linecap="round"/>
                        <path d="M11 1L0.999999 11" stroke="#808F9F" stroke-width="1.1" stroke-linecap="round"/>
                    </svg>
                </span>
            </div>
            <div class="new-notifications-modal-body">
                <p>
                    {{ item.title }}
                </p>
                <span>
                    {{ item.time }}
                </span>
            </div>
        </div>
    </div>
</template>
<script>
import { mapMutations } from "vuex";
export default {
    props: {
        item: {
            type: Object,
            default: null
        }
    },

    methods: {
        ...mapMutations({
            clearNewNotifications: 'notifications/clearNewNotifications'
        }),

        closeModal() {
            this.clearNewNotifications()
        }
    }
}
</script>
<style lang="sass" scoped>
.new-notifications-modal-wrapper
    position: fixed
    right: 36px
    bottom: 36px

.new-notifications-modal
    padding: 0 20px
    display: flex
    flex-direction: column
    width: 330px
    background: #fff
    box-shadow: 0px 10px 30px 0px #00000040
    border-radius: 5px
    &-header
        display: flex
        align-items: center
        justify-content: space-between
        height: 50px
        font-size: 14px
        font-weight: 700
        & span
            opacity: .5
            transition: .2s ease
            cursor: pointer
            &:hover
                opacity: 1
    &-body
        padding: 20px 0
        display: flex
        flex-direction: column
        gap: 8px
        & p
            font-size: 14px
            font-weight: 700
        & span
            font-size: 12px
            color: #808F9F
</style>