<template>
    <div class="window window-tasks window_full" v-if="reviews">
        <div class="window-header">
            <img
                src="@/assets/img/icons/icon-reviews.svg"
                alt="Иконка"
                class="window-header__icon"
            />
            <div class="window-header__title">Отзывы</div>
            <div class="reviews-toggler">
                <span
                    v-for="(item, index) in reviews"
                    :key="index"
                    @click="toggler = index"
                    :class="toggler == index ? 'active' : ''"
                >
                    {{ index }}
                </span>
            </div>
        </div>
        <div class="window-body" v-if="reviews && toggler">
            <div class="reviews-body">
                <div class="reviews-body-points">
                    <p>{{ reviews[toggler].average_rating }}</p>
                    <div class="reviews-body-points-stars">
                        <svg
                            width="13"
                            height="12"
                            viewBox="0 0 13 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <mask x="0" y="0" id="star_1">
                                <rect
                                    v-if="reviews[toggler].average_rating > 1"
                                    fill="#7992F9"
                                    width="100%"
                                    height="100%"
                                />
                                <rect
                                    v-if="reviews[toggler].average_rating < 1"
                                    fill="#7992F9"
                                    width="50%"
                                    height="100%"
                                />
                            </mask>
                            <path
                                d="M6.02447 0.463526C6.17415 0.00287056 6.82585 0.00286996 6.97553 0.463525L8.0716 3.83688C8.13854 4.04289 8.33051 4.18237 8.54713 4.18237H12.0941C12.5784 4.18237 12.7798 4.80218 12.388 5.08688L9.51843 7.17173C9.34318 7.29905 9.26985 7.52474 9.33679 7.73075L10.4329 11.1041C10.5825 11.5648 10.0553 11.9478 9.66344 11.6631L6.79389 9.57827C6.61865 9.45095 6.38135 9.45095 6.20611 9.57827L3.33656 11.6631C2.9447 11.9478 2.41746 11.5648 2.56714 11.1041L3.66321 7.73075C3.73015 7.52474 3.65682 7.29905 3.48157 7.17173L0.612025 5.08688C0.220169 4.80218 0.421556 4.18237 0.905918 4.18237H4.45287C4.66949 4.18237 4.86146 4.04289 4.9284 3.83688L6.02447 0.463526Z"
                                fill="#D9D9D9"
                            />
                            <path
                                d="M6.02447 0.463526C6.17415 0.00287056 6.82585 0.00286996 6.97553 0.463525L8.0716 3.83688C8.13854 4.04289 8.33051 4.18237 8.54713 4.18237H12.0941C12.5784 4.18237 12.7798 4.80218 12.388 5.08688L9.51843 7.17173C9.34318 7.29905 9.26985 7.52474 9.33679 7.73075L10.4329 11.1041C10.5825 11.5648 10.0553 11.9478 9.66344 11.6631L6.79389 9.57827C6.61865 9.45095 6.38135 9.45095 6.20611 9.57827L3.33656 11.6631C2.9447 11.9478 2.41746 11.5648 2.56714 11.1041L3.66321 7.73075C3.73015 7.52474 3.65682 7.29905 3.48157 7.17173L0.612025 5.08688C0.220169 4.80218 0.421556 4.18237 0.905918 4.18237H4.45287C4.66949 4.18237 4.86146 4.04289 4.9284 3.83688L6.02447 0.463526Z"
                                fill="#7992F9"
                                mask="url(#star_1)"
                            />
                        </svg>
                        <svg
                            width="13"
                            height="12"
                            viewBox="0 0 13 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <mask x="0" y="0" id="star_2">
                                <rect
                                    v-if="reviews[toggler].average_rating > 2"
                                    fill="#7992F9"
                                    width="100%"
                                    height="100%"
                                />
                                <rect
                                    v-if="reviews[toggler].average_rating < 2"
                                    fill="#7992F9"
                                    width="50%"
                                    height="100%"
                                />
                            </mask>
                            <path
                                d="M6.02447 0.463526C6.17415 0.00287056 6.82585 0.00286996 6.97553 0.463525L8.0716 3.83688C8.13854 4.04289 8.33051 4.18237 8.54713 4.18237H12.0941C12.5784 4.18237 12.7798 4.80218 12.388 5.08688L9.51843 7.17173C9.34318 7.29905 9.26985 7.52474 9.33679 7.73075L10.4329 11.1041C10.5825 11.5648 10.0553 11.9478 9.66344 11.6631L6.79389 9.57827C6.61865 9.45095 6.38135 9.45095 6.20611 9.57827L3.33656 11.6631C2.9447 11.9478 2.41746 11.5648 2.56714 11.1041L3.66321 7.73075C3.73015 7.52474 3.65682 7.29905 3.48157 7.17173L0.612025 5.08688C0.220169 4.80218 0.421556 4.18237 0.905918 4.18237H4.45287C4.66949 4.18237 4.86146 4.04289 4.9284 3.83688L6.02447 0.463526Z"
                                fill="#D9D9D9"
                            />
                            <path
                                d="M6.02447 0.463526C6.17415 0.00287056 6.82585 0.00286996 6.97553 0.463525L8.0716 3.83688C8.13854 4.04289 8.33051 4.18237 8.54713 4.18237H12.0941C12.5784 4.18237 12.7798 4.80218 12.388 5.08688L9.51843 7.17173C9.34318 7.29905 9.26985 7.52474 9.33679 7.73075L10.4329 11.1041C10.5825 11.5648 10.0553 11.9478 9.66344 11.6631L6.79389 9.57827C6.61865 9.45095 6.38135 9.45095 6.20611 9.57827L3.33656 11.6631C2.9447 11.9478 2.41746 11.5648 2.56714 11.1041L3.66321 7.73075C3.73015 7.52474 3.65682 7.29905 3.48157 7.17173L0.612025 5.08688C0.220169 4.80218 0.421556 4.18237 0.905918 4.18237H4.45287C4.66949 4.18237 4.86146 4.04289 4.9284 3.83688L6.02447 0.463526Z"
                                fill="#7992F9"
                                mask="url(#star_2)"
                            />
                        </svg>
                        <svg
                            width="13"
                            height="12"
                            viewBox="0 0 13 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <mask x="0" y="0" id="star_3">
                                <rect
                                    v-if="reviews[toggler].average_rating > 3"
                                    fill="#7992F9"
                                    width="100%"
                                    height="100%"
                                />
                                <rect
                                    v-if="reviews[toggler].average_rating < 3"
                                    fill="#7992F9"
                                    width="50%"
                                    height="100%"
                                />
                            </mask>
                            <path
                                d="M6.02447 0.463526C6.17415 0.00287056 6.82585 0.00286996 6.97553 0.463525L8.0716 3.83688C8.13854 4.04289 8.33051 4.18237 8.54713 4.18237H12.0941C12.5784 4.18237 12.7798 4.80218 12.388 5.08688L9.51843 7.17173C9.34318 7.29905 9.26985 7.52474 9.33679 7.73075L10.4329 11.1041C10.5825 11.5648 10.0553 11.9478 9.66344 11.6631L6.79389 9.57827C6.61865 9.45095 6.38135 9.45095 6.20611 9.57827L3.33656 11.6631C2.9447 11.9478 2.41746 11.5648 2.56714 11.1041L3.66321 7.73075C3.73015 7.52474 3.65682 7.29905 3.48157 7.17173L0.612025 5.08688C0.220169 4.80218 0.421556 4.18237 0.905918 4.18237H4.45287C4.66949 4.18237 4.86146 4.04289 4.9284 3.83688L6.02447 0.463526Z"
                                fill="#D9D9D9"
                            />
                            <path
                                d="M6.02447 0.463526C6.17415 0.00287056 6.82585 0.00286996 6.97553 0.463525L8.0716 3.83688C8.13854 4.04289 8.33051 4.18237 8.54713 4.18237H12.0941C12.5784 4.18237 12.7798 4.80218 12.388 5.08688L9.51843 7.17173C9.34318 7.29905 9.26985 7.52474 9.33679 7.73075L10.4329 11.1041C10.5825 11.5648 10.0553 11.9478 9.66344 11.6631L6.79389 9.57827C6.61865 9.45095 6.38135 9.45095 6.20611 9.57827L3.33656 11.6631C2.9447 11.9478 2.41746 11.5648 2.56714 11.1041L3.66321 7.73075C3.73015 7.52474 3.65682 7.29905 3.48157 7.17173L0.612025 5.08688C0.220169 4.80218 0.421556 4.18237 0.905918 4.18237H4.45287C4.66949 4.18237 4.86146 4.04289 4.9284 3.83688L6.02447 0.463526Z"
                                fill="#7992F9"
                                mask="url(#star_3)"
                            />
                        </svg>
                        <svg
                            width="13"
                            height="12"
                            viewBox="0 0 13 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <mask x="0" y="0" id="star_4">
                                <rect
                                    v-if="reviews[toggler].average_rating > 4"
                                    fill="#7992F9"
                                    width="100%"
                                    height="100%"
                                />
                                <rect
                                    v-if="reviews[toggler].average_rating < 4"
                                    fill="#7992F9"
                                    width="50%"
                                    height="100%"
                                />
                            </mask>
                            <path
                                d="M6.02447 0.463526C6.17415 0.00287056 6.82585 0.00286996 6.97553 0.463525L8.0716 3.83688C8.13854 4.04289 8.33051 4.18237 8.54713 4.18237H12.0941C12.5784 4.18237 12.7798 4.80218 12.388 5.08688L9.51843 7.17173C9.34318 7.29905 9.26985 7.52474 9.33679 7.73075L10.4329 11.1041C10.5825 11.5648 10.0553 11.9478 9.66344 11.6631L6.79389 9.57827C6.61865 9.45095 6.38135 9.45095 6.20611 9.57827L3.33656 11.6631C2.9447 11.9478 2.41746 11.5648 2.56714 11.1041L3.66321 7.73075C3.73015 7.52474 3.65682 7.29905 3.48157 7.17173L0.612025 5.08688C0.220169 4.80218 0.421556 4.18237 0.905918 4.18237H4.45287C4.66949 4.18237 4.86146 4.04289 4.9284 3.83688L6.02447 0.463526Z"
                                fill="#D9D9D9"
                            />
                            <path
                                d="M6.02447 0.463526C6.17415 0.00287056 6.82585 0.00286996 6.97553 0.463525L8.0716 3.83688C8.13854 4.04289 8.33051 4.18237 8.54713 4.18237H12.0941C12.5784 4.18237 12.7798 4.80218 12.388 5.08688L9.51843 7.17173C9.34318 7.29905 9.26985 7.52474 9.33679 7.73075L10.4329 11.1041C10.5825 11.5648 10.0553 11.9478 9.66344 11.6631L6.79389 9.57827C6.61865 9.45095 6.38135 9.45095 6.20611 9.57827L3.33656 11.6631C2.9447 11.9478 2.41746 11.5648 2.56714 11.1041L3.66321 7.73075C3.73015 7.52474 3.65682 7.29905 3.48157 7.17173L0.612025 5.08688C0.220169 4.80218 0.421556 4.18237 0.905918 4.18237H4.45287C4.66949 4.18237 4.86146 4.04289 4.9284 3.83688L6.02447 0.463526Z"
                                fill="#7992F9"
                                mask="url(#star_4)"
                            />
                        </svg>
                        <svg
                            width="13"
                            height="12"
                            viewBox="0 0 13 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <mask x="0" y="0" id="star_5">
                                <rect
                                    v-if="reviews[toggler].average_rating > 5"
                                    fill="#7992F9"
                                    width="100%"
                                    height="100%"
                                />
                                <rect
                                    v-if="reviews[toggler].average_rating < 5"
                                    fill="#7992F9"
                                    width="50%"
                                    height="100%"
                                />
                            </mask>
                            <path
                                d="M6.02447 0.463526C6.17415 0.00287056 6.82585 0.00286996 6.97553 0.463525L8.0716 3.83688C8.13854 4.04289 8.33051 4.18237 8.54713 4.18237H12.0941C12.5784 4.18237 12.7798 4.80218 12.388 5.08688L9.51843 7.17173C9.34318 7.29905 9.26985 7.52474 9.33679 7.73075L10.4329 11.1041C10.5825 11.5648 10.0553 11.9478 9.66344 11.6631L6.79389 9.57827C6.61865 9.45095 6.38135 9.45095 6.20611 9.57827L3.33656 11.6631C2.9447 11.9478 2.41746 11.5648 2.56714 11.1041L3.66321 7.73075C3.73015 7.52474 3.65682 7.29905 3.48157 7.17173L0.612025 5.08688C0.220169 4.80218 0.421556 4.18237 0.905918 4.18237H4.45287C4.66949 4.18237 4.86146 4.04289 4.9284 3.83688L6.02447 0.463526Z"
                                fill="#D9D9D9"
                            />
                            <path
                                d="M6.02447 0.463526C6.17415 0.00287056 6.82585 0.00286996 6.97553 0.463525L8.0716 3.83688C8.13854 4.04289 8.33051 4.18237 8.54713 4.18237H12.0941C12.5784 4.18237 12.7798 4.80218 12.388 5.08688L9.51843 7.17173C9.34318 7.29905 9.26985 7.52474 9.33679 7.73075L10.4329 11.1041C10.5825 11.5648 10.0553 11.9478 9.66344 11.6631L6.79389 9.57827C6.61865 9.45095 6.38135 9.45095 6.20611 9.57827L3.33656 11.6631C2.9447 11.9478 2.41746 11.5648 2.56714 11.1041L3.66321 7.73075C3.73015 7.52474 3.65682 7.29905 3.48157 7.17173L0.612025 5.08688C0.220169 4.80218 0.421556 4.18237 0.905918 4.18237H4.45287C4.66949 4.18237 4.86146 4.04289 4.9284 3.83688L6.02447 0.463526Z"
                                fill="#7992F9"
                                mask="url(#star_5)"
                            />
                        </svg>
                    </div>
                    <span>{{ reviews[toggler].reviews_count }} отзывов</span>
                </div>
                <div class="reviews-body-stats">
                    <div class="reviews-body-stats-item">
                        <div class="reviews-body-stats-item__stars">
                            <svg
                                v-for="n in 5"
                                :key="n"
                                width="13"
                                height="12"
                                viewBox="0 0 13 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M6.02447 0.463526C6.17415 0.00287056 6.82585 0.00286996 6.97553 0.463525L8.0716 3.83688C8.13854 4.04289 8.33051 4.18237 8.54713 4.18237H12.0941C12.5784 4.18237 12.7798 4.80218 12.388 5.08688L9.51843 7.17173C9.34318 7.29905 9.26985 7.52474 9.33679 7.73075L10.4329 11.1041C10.5825 11.5648 10.0553 11.9478 9.66344 11.6631L6.79389 9.57827C6.61865 9.45095 6.38135 9.45095 6.20611 9.57827L3.33656 11.6631C2.9447 11.9478 2.41746 11.5648 2.56714 11.1041L3.66321 7.73075C3.73015 7.52474 3.65682 7.29905 3.48157 7.17173L0.612025 5.08688C0.220169 4.80218 0.421556 4.18237 0.905918 4.18237H4.45287C4.66949 4.18237 4.86146 4.04289 4.9284 3.83688L6.02447 0.463526Z"
                                    fill="#D9D9D9"
                                />
                            </svg>
                        </div>
                        <div class="reviews-body-stats-item__bar">
                            <div class="reviews-body-stats-item__bar_bg"></div>
                            <div
                                class="reviews-body-stats-item__bar_fill"
                                :style="`width: ${reviews[toggler].rating_percents[5]}%`"
                            ></div>
                        </div>
                        <span>{{ reviews[toggler].rating_percents[5] }}%</span>
                    </div>
                    <div class="reviews-body-stats-item">
                        <div class="reviews-body-stats-item__stars">
                            <svg
                                v-for="n in 4"
                                :key="n"
                                width="13"
                                height="12"
                                viewBox="0 0 13 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M6.02447 0.463526C6.17415 0.00287056 6.82585 0.00286996 6.97553 0.463525L8.0716 3.83688C8.13854 4.04289 8.33051 4.18237 8.54713 4.18237H12.0941C12.5784 4.18237 12.7798 4.80218 12.388 5.08688L9.51843 7.17173C9.34318 7.29905 9.26985 7.52474 9.33679 7.73075L10.4329 11.1041C10.5825 11.5648 10.0553 11.9478 9.66344 11.6631L6.79389 9.57827C6.61865 9.45095 6.38135 9.45095 6.20611 9.57827L3.33656 11.6631C2.9447 11.9478 2.41746 11.5648 2.56714 11.1041L3.66321 7.73075C3.73015 7.52474 3.65682 7.29905 3.48157 7.17173L0.612025 5.08688C0.220169 4.80218 0.421556 4.18237 0.905918 4.18237H4.45287C4.66949 4.18237 4.86146 4.04289 4.9284 3.83688L6.02447 0.463526Z"
                                    fill="#D9D9D9"
                                />
                            </svg>
                        </div>
                        <div class="reviews-body-stats-item__bar">
                            <div class="reviews-body-stats-item__bar_bg"></div>
                            <div
                                class="reviews-body-stats-item__bar_fill"
                                :style="`width: ${reviews[toggler].rating_percents[4]}%`"
                            ></div>
                        </div>
                        <span>{{ reviews[toggler].rating_percents[4] }}%</span>
                    </div>
                    <div class="reviews-body-stats-item">
                        <div class="reviews-body-stats-item__stars">
                            <svg
                                v-for="n in 3"
                                :key="n"
                                width="13"
                                height="12"
                                viewBox="0 0 13 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M6.02447 0.463526C6.17415 0.00287056 6.82585 0.00286996 6.97553 0.463525L8.0716 3.83688C8.13854 4.04289 8.33051 4.18237 8.54713 4.18237H12.0941C12.5784 4.18237 12.7798 4.80218 12.388 5.08688L9.51843 7.17173C9.34318 7.29905 9.26985 7.52474 9.33679 7.73075L10.4329 11.1041C10.5825 11.5648 10.0553 11.9478 9.66344 11.6631L6.79389 9.57827C6.61865 9.45095 6.38135 9.45095 6.20611 9.57827L3.33656 11.6631C2.9447 11.9478 2.41746 11.5648 2.56714 11.1041L3.66321 7.73075C3.73015 7.52474 3.65682 7.29905 3.48157 7.17173L0.612025 5.08688C0.220169 4.80218 0.421556 4.18237 0.905918 4.18237H4.45287C4.66949 4.18237 4.86146 4.04289 4.9284 3.83688L6.02447 0.463526Z"
                                    fill="#D9D9D9"
                                />
                            </svg>
                        </div>
                        <div class="reviews-body-stats-item__bar">
                            <div class="reviews-body-stats-item__bar_bg"></div>
                            <div
                                class="reviews-body-stats-item__bar_fill"
                                :style="`width: ${reviews[toggler].rating_percents[3]}%`"
                            ></div>
                        </div>
                        <span>{{ reviews[toggler].rating_percents[3] }}%</span>
                    </div>
                    <div class="reviews-body-stats-item">
                        <div class="reviews-body-stats-item__stars">
                            <svg
                                v-for="n in 2"
                                :key="n"
                                width="13"
                                height="12"
                                viewBox="0 0 13 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M6.02447 0.463526C6.17415 0.00287056 6.82585 0.00286996 6.97553 0.463525L8.0716 3.83688C8.13854 4.04289 8.33051 4.18237 8.54713 4.18237H12.0941C12.5784 4.18237 12.7798 4.80218 12.388 5.08688L9.51843 7.17173C9.34318 7.29905 9.26985 7.52474 9.33679 7.73075L10.4329 11.1041C10.5825 11.5648 10.0553 11.9478 9.66344 11.6631L6.79389 9.57827C6.61865 9.45095 6.38135 9.45095 6.20611 9.57827L3.33656 11.6631C2.9447 11.9478 2.41746 11.5648 2.56714 11.1041L3.66321 7.73075C3.73015 7.52474 3.65682 7.29905 3.48157 7.17173L0.612025 5.08688C0.220169 4.80218 0.421556 4.18237 0.905918 4.18237H4.45287C4.66949 4.18237 4.86146 4.04289 4.9284 3.83688L6.02447 0.463526Z"
                                    fill="#D9D9D9"
                                />
                            </svg>
                        </div>
                        <div class="reviews-body-stats-item__bar">
                            <div class="reviews-body-stats-item__bar_bg"></div>
                            <div
                                class="reviews-body-stats-item__bar_fill"
                                :style="`width: ${reviews[toggler].rating_percents[2]}%`"
                            ></div>
                        </div>
                        <span>{{ reviews[toggler].rating_percents[2] }}%</span>
                    </div>
                    <div class="reviews-body-stats-item">
                        <div class="reviews-body-stats-item__stars">
                            <svg
                                width="13"
                                height="12"
                                viewBox="0 0 13 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M6.02447 0.463526C6.17415 0.00287056 6.82585 0.00286996 6.97553 0.463525L8.0716 3.83688C8.13854 4.04289 8.33051 4.18237 8.54713 4.18237H12.0941C12.5784 4.18237 12.7798 4.80218 12.388 5.08688L9.51843 7.17173C9.34318 7.29905 9.26985 7.52474 9.33679 7.73075L10.4329 11.1041C10.5825 11.5648 10.0553 11.9478 9.66344 11.6631L6.79389 9.57827C6.61865 9.45095 6.38135 9.45095 6.20611 9.57827L3.33656 11.6631C2.9447 11.9478 2.41746 11.5648 2.56714 11.1041L3.66321 7.73075C3.73015 7.52474 3.65682 7.29905 3.48157 7.17173L0.612025 5.08688C0.220169 4.80218 0.421556 4.18237 0.905918 4.18237H4.45287C4.66949 4.18237 4.86146 4.04289 4.9284 3.83688L6.02447 0.463526Z"
                                    fill="#D9D9D9"
                                />
                            </svg>
                        </div>
                        <div class="reviews-body-stats-item__bar">
                            <div class="reviews-body-stats-item__bar_bg"></div>
                            <div
                                class="reviews-body-stats-item__bar_fill"
                                :style="`width: ${reviews[toggler].rating_percents[1]}%`"
                            ></div>
                        </div>
                        <span>{{ reviews[toggler].rating_percents[1] }}%</span>
                    </div>
                </div>
                <swiper :navigation="true" :modules="modules" :loop="true">
                    <swiper-slide class="reviews-body-review" v-for="review in reviews[toggler].reviews">
                        <div class="reviews-body-review-header">
                            <div class="reviews-body-review-header__avatar"></div>
                            <div class="reviews-body-review-header__name">{{ review[2] }}</div>
                            <div class="reviews-body-review-header__stars">
                                <svg v-for="n in review[1]" :key="n" width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.02447 0.463526C6.17415 0.00287056 6.82585 0.00286996 6.97553 0.463525L8.0716 3.83688C8.13854 4.04289 8.33051 4.18237 8.54713 4.18237H12.0941C12.5784 4.18237 12.7798 4.80218 12.388 5.08688L9.51843 7.17173C9.34318 7.29905 9.26985 7.52474 9.33679 7.73075L10.4329 11.1041C10.5825 11.5648 10.0553 11.9478 9.66344 11.6631L6.79389 9.57827C6.61865 9.45095 6.38135 9.45095 6.20611 9.57827L3.33656 11.6631C2.9447 11.9478 2.41746 11.5648 2.56714 11.1041L3.66321 7.73075C3.73015 7.52474 3.65682 7.29905 3.48157 7.17173L0.612025 5.08688C0.220169 4.80218 0.421556 4.18237 0.905918 4.18237H4.45287C4.66949 4.18237 4.86146 4.04289 4.9284 3.83688L6.02447 0.463526Z" fill="#D9D9D9"/>
                                </svg>
                            </div>
                        </div>
                        <div class="reviews-body-review-body">
                            <p>{{ review[0] }}</p>
                        </div>
                    </swiper-slide>
                </swiper>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation } from "swiper/modules";
import 'swiper/css';
import 'swiper/css/navigation';

export default {
    data() {
        return {
            toggler: null,
            currentSlide: 0,
        };
    },

    components: {
        Swiper,
        SwiperSlide,
    },

    setup() {
        return {
            modules: [Navigation],
        };
    },

    computed: {
        ...mapState({
            reviews: (state) => state.home.reviews,
        }),
    },

    methods: {
        ...mapActions({
            getReviews: "home/getReviews",
        }),
    },

    watch: {
        reviews(newValue) {
            if(newValue) {
                for(let shop in newValue) {
                    if(!this.toggler) {
                        this.toggler = shop
                    } else {
                        return
                    }
                }
            }
        }
    },

    mounted() {
        this.getReviews();
    },
};
</script>
<style scoped lang="sass">
.window.sm
    & .reviews-body
        grid-template-columns: repeat(2, auto)
        & .swiper
            grid-column: 1 / -1
.reviews
    &-toggler
        margin-left: auto
        padding: 2px
        display: grid
        grid-template-columns: repeat(3, 1fr)
        height: 30px
        background: var(--color-white)
        border-radius: 4px
        & span
            padding: 4px 8px
            display: flex
            align-items: center
            justify-content: center
            width: 100%
            height: 100%
            border-radius: 4px
            transition: .2s ease
            cursor: pointer
            &.active
                color: var(--color-white)
                background: var(--color-blue)
    &-body
        padding: 18px 18px 20px 18px
        display: grid
        grid-template-columns: auto auto 1fr
        grid-gap: 26px
        @media only screen and (max-width: 1024px)
            grid-template-columns: repeat(2, auto)
            justify-content: space-between
        &-points
            display: flex
            flex-direction: column
            & p
                margin-bottom: 8px
                font-size: 60px
                line-height: 72px
                color: var(--color-blue)
                font-weight: 400
            &-stars
                margin-bottom: 12px
                display: flex
                align-items: center
                gap: 2px
            & span
                color: var(--color-text-ligth)
        &-stats
            display: flex
            flex-direction: column
            gap: 10px
            @media only screen and (max-width: 1024px)
                align-items: flex-end
            &-item
                display: grid
                grid-template-columns: 100px 214px 40px
                grid-column-gap: 12px
                align-items: center
                justify-items: end
                &__stars
                    display: flex
                    justify-content: flex-end
                    gap: 4px
                    width: 100%
                &__bar
                    position: relative
                    width: 214px
                    height: 10px
                    &_bg
                        position: absolute
                        width: 100%
                        height: 100%
                        background: var(--color-light-beige)
                        border-radius: 1px
                    &_fill
                        position: absolute
                        height: 100%
                        background: var(--color-blue)
                        border-radius: 1px
        & .swiper
            @media only screen and (max-width: 1024px)
                grid-column: 1 / -1
        &-review
            position: relative
            padding: 12px 24px 8px 16px
            display: flex
            flex-direction: column
            gap: 8px
            height: 100%
            background: var(--color-light-beige)
            border-radius: var(--border-radius)
            &-header
                display: flex
                align-items: center
                gap: 8px
                &__avatar
                    width: 30px
                    height: 30px
                    border-radius: 1000px
                    background: #D9D9D9
                &__name
                    font-size: 14px
                    line-height: 24px
                    color: var(--color-text-ligth)
                &__stars
                    margin-left: 4px
                    display: flex
                    align-items: center
                    gap: 4px
                    height: 100%
            &-body
                font-size: 14px
                line-height: 18px
</style>
