import axios from 'axios'
import api from '@/api'

export const homeModule = {
    namespaced: true,

    state:() => ({
        goalsList: [],
        goalsCount: {
            total: 0,
            active: 0,
            completed: 0
        },
        metriks: {},
        notifications: [],
        users: null,
        reviews: null,
        polls_results: null
    }),

    mutations: {
        setGoals(state, data) {
            state.goalsList = data
            for(let i = 0; i < data.length; i++) {

                state.goalsList[i].current_value = data[i].current_value.toLocaleString()
                state.goalsList[i].planned_value = data[i].planned_value.toLocaleString()

                state.goalsCount.total = data.length
                if(data[i].is_completed) {
                    state.goalsCount.completed ++
                }
            }
        },
        setMetriks(state,data) {
            state.metriks = data
            
            for(let i = 0; i < state.metriks.length; i++) {
                if(state.metriks[i].value) {
                    state.metriks[i].value = state.metriks[i].value.toLocaleString()
                }
            }
        },
        setNotifications(state, data) {
            state.notifications = data
        },

        setUsers(state, data) {
            state.users = data
        },

        setReviews(state, data) {
            console.log(data);
            state.reviews = data
        },

        setPollsResults(state, data) {
            state.polls_results = data
        }
    },

    actions: {
        async getGoalsList({ commit, rootState }) {
            rootState.isLoading = true

            try {
                const response = await axios.get(api + "/aims/", {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    }
                })
                commit('setGoals', response.data)
            } catch(err) { // Если есть ошибка
                alert(err)
            }

            rootState.isLoading = false
        },

        async getMetriks({ commit, rootState }) {
            rootState.isLoading = true

            try {
                const response = await axios.get(api + "/metrics/", {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    }
                })
                commit('setMetriks', response.data)
            } catch(err) { // Если есть ошибка
                alert(err)
            }

            rootState.isLoading = false
        },

        async getShop ({ commit, rootState }) {
            rootState.isLoading = true

            try {
                const response = await axios.get(api + "/products/", {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    }
                })
            } catch (err) {
                alert(err)
            }

            rootState.isLoading = false
        },

        async getUsers ({ commit }) {
            try {
                const response = await axios.get(api + "/users/", {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    }
                })
                commit('setUsers', response.data)
            } catch (err) {
                alert(err)
            }
        },

        async getReviews ({ commit }) {
            try {
                const response = await axios.get("https://admin.tcode.online/api/projects/tubatay/metrics-nps/", {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    }
                })
                commit('setReviews', response.data)
            } catch (err) {
                alert(err)
            }
        },

        async getPollsResults ({ commit }) {
            try {
                const response = await axios.get("https://admin.tcode.online/api/projects/tcode/poll-results/", {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    }
                })
                commit('setPollsResults', response.data)
            } catch (err) {
                alert(err)
            }
        }
    }
}